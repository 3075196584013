import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashboardLayout from './components/layouts/DashboardLayout';
import Dashboard from './pages/DashboardScreen/Index';
import Users from './pages/DashboardScreen/Users';
import Login from './pages/Login';
import Settings from './pages/DashboardScreen/Settings/Index';
import ManageAccounts from './pages/DashboardScreen/Settings/ManageAccounts';
import System from './pages/DashboardScreen/Settings/System';
import Events from './pages/DashboardScreen/Events';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { logout } from './Slices/authSlice';
import AuthRoute from './components/AuthRoute';
import Testimonial from './pages/DashboardScreen/Testimonial';
import Devotionals from './pages/DashboardScreen/Devotionals';
import Bulletin from './pages/DashboardScreen/Prayer/Bulletin';
import Request from './pages/DashboardScreen/Prayer/Request';
import Blog from './pages/DashboardScreen/Blog';
import Gallery from './pages/DashboardScreen/Gallery';
import Zones from './pages/DashboardScreen/Zones';
import Intro from './pages/DashboardScreen/Intro';
import Word from './pages/DashboardScreen/word';
import Projects from './pages/DashboardScreen/Projects';
import Audio from './pages/DashboardScreen/Audio';
import Video from './pages/DashboardScreen/Video';
import Leaders from './pages/DashboardScreen/Leaders';


function App() {
	return (
    <div className="font-Lato">
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />

          <Route path="" element={<AuthRoute />}>
            <Route path="dashboard" element={<DashboardLayout />}>
              <Route path="index" element={<Dashboard />} />
              <Route path="users" element={<Users />} />
              <Route path="settings" element={<Settings />} />
              <Route path="settings/accounts" element={<ManageAccounts />} />
              <Route
                path="settings/system_configuration"
                element={<System />}
              />
              <Route path="events" element={<Events />} />
              <Route path="intro" element={<Intro />} />
              <Route path="words" element={<Word />} />
              <Route path="videos" element={<Video />} />
              <Route path="audio" element={<Audio />} />
              <Route path="testimonial" element={<Testimonial />} />
              <Route path="devotionals" element={<Devotionals />} />
              <Route path="prayer/bulletin" element={<Bulletin />} />
              <Route path="prayer/request" element={<Request />} />
              <Route path="blog" element={<Blog />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="projects" element={<Projects />} />
              <Route path="zones" element={<Zones />} />
              <Route path="leaders" element={<Leaders />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
